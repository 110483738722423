import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../../components/header"
import Footer from "../../../components/footer"
import "../../../styles/style.css"
import Location  from "../../../components/services/location_cloud"

const SoporteValencia = () => {
  const data = useStaticQuery(graphql`
    query Images_soporte_valencia {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Soporte Informático 24/7 en VALENCIA | Qualoom</title>
        <meta name="title" content="Soporte Informático 24/7 en VALENCIA | Qualoom"/>
        <meta name="description" content="En Qualoom tenemos Especialistas en Soporte Informático 24h los 7 días en Valencia que proporcionan asistencia técnica y soporte remoto ¡Infórmate!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/soporte-informatico/valencia/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/soporte-informatico/valencia/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/soporte-informatico/valencia/"/>
        <meta property="og:title" content="Soporte Informático 24/7 en VALENCIA | Qualoom"/>
        <meta property="og:description" content="En Qualoom tenemos Especialistas en Soporte Informático 24h los 7 días en Valencia que proporcionan asistencia técnica y soporte remoto ¡Infórmate!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/soporte-informatico/valencia/"/>
        <meta property="twitter:title" content="Soporte Informático 24/7 en VALENCIA | Qualoom"/>
        <meta property="twitter:description" content="En Qualoom tenemos Especialistas en Soporte Informático 24h los 7 días en Valencia que proporcionan asistencia técnica y soporte remoto ¡Infórmate!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/soporte-informatico/valencia/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}},
              {"@type":"ListItem", "position":"3", "name":"Soporte informático 8x5, 12x5 & 24x7", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/soporte-informatico/", "name": "Soporte informático 8x5, 12x5 & 24x7"}},
              {"@type":"ListItem", "position":"4", "name":"Soporte informático 12x5 & 24x7 en Valencia", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/soporte-informatico/valencia/", "name": "Soporte informático 12x5 & 24x7 en Valencia"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Soporte informático 12x5 & 24x7 en VALENCIA</h1>
              <p>¿Tienes sistemas de información, soluciones de comercio electrónico u otro tipo de aplicaciones importantes para tu negocio? Si es así, es posible que necesites expertos en su mantenimiento y soporte, sobre todo en horarios fuera de la atención habitual.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Soporte técnico informático para empresas</h2><hr></hr>
          <p><b>Qualoom brinda la capacidad de tener un equipo de soporte en distintas modalidades</b> para que sus sistemas de información sean monitoreados y respaldados en caso de un problema. Contacta con nosotros para saber más sobre este <b>servicio especializado 8x5, 12x5 o 24x7</b>. </p>
          <p>¿Tiene una empresa que utiliza los recursos de TI de su organización a diario? ¿Sus empleados tienen que usar herramientas de colaboración, software de oficina, administración de licencias, impresoras, dispositivos electrónicos de red, servidores locales y más? <b>Qualoom puede ayudarte a brindar el mejor soporte, mantener tus dispositivos e impresoras, y ofrecer soluciones y consejos de mejores prácticas para la toma de decisiones</b>.</p><br/>
          <h3>¿Para qué sirve el soporte técnico?</h3>
          <p>En Qualoom, <b>ofrecemos soporte las 24 horas del día, los 7 días de la semana para ayudarte a administrar y resolver cualquier problema o fallo de infraestructura en la nube/híbrido/local</b> lo antes posible. Nuestro objetivo es que nuestros clientes estén tranquilos sabiendo que pueden contar con nuestro equipo de expertos cuando más lo necesitan en cualquier campo: aplicaciones ofimáticas, especialistas de soporte Cloud, arquitectos de información, etc.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿Cuándo ofrecemos soporte técnico a empresas?</h2><hr></hr>
          <p>Existen distintos tipos de modelo de soporte, te presentamos los más comunes:</p><br/>
          <div className="soporte-table-responsive">
            <h3><b>Soporte 8x5</b></h3><hr></hr>
            <ul>
              <li><b>Horario de soporte</b> (Madrid time zone)</li>
              <ul><li><b>Horario de oficina ordinario</b><br/>Lunes a Jueves de 09:00 a 14:00 / 15:00 a 18:00<br/>Viernes 09:00 a 15:00</li><li><b>Horario mes de agosto</b><br/>Lunes a Viernes 09:00 - 15:00</li></ul>
              <li><b>Dedicación máxima mensual para consultas o incidencias</b></li>
              <ul><li>1,5 jornadas</li></ul>
              <li><b>SLA Tiempo de respuesta</b></li>
              <ul><li>1 hora</li></ul>
              <li><b>Nº puestas en producción mensuales fuera de horario</b> (después de las 22:00)</li>
              <ul><li>1 puesta en prod./mes</li></ul>
            </ul><br/>
            <h3><b>Soporte 12x5</b></h3><hr></hr>
            <ul>
              <li><b>Horario de soporte</b> (Madrid time zone)</li>
              <ul><li>Lunes a Viernes 9.00 - 21.00</li></ul>
              <li><b>Dedicación máxima mensual para consultas o incidencias</b></li>
              <ul><li>2 jornadas</li></ul>
              <li><b>SLA Tiempo de respuesta</b></li>
              <ul><li>1 hora</li></ul>
              <li><b>Nº puestas en producción mensuales fuera de horario</b> (después de las 22:00)</li>
              <ul><li>1 puesta en prod./mes</li></ul>
            </ul><br/>
            <h3><b>Soporte 24x7</b></h3><hr></hr>
            <ul>
              <li><b>Horario de soporte</b> (Madrid time zone)</li>
              <ul><li>Lunes a Domingo / 24 horas / 365 días al año</li></ul>
              <li><b>Dedicación máxima mensual para consultas o incidencias</b></li>
              <ul><li>3 jornadas</li></ul>
              <li><b>SLA Tiempo de respuesta</b></li>
              <ul><li>1 hora</li></ul>
              <li><b>Nº puestas en producción mensuales fuera de horario</b> (después de las 22:00)</li>
              <ul><li>1 puesta en prod./mes</li></ul>
            </ul><br/>
          </div>
          <div className="soporte-table">
            <div className="header-cell first-cell"></div>
            <div className="row">
              <div className="normal-cell-3 first-row"><p>Soporte 8x5</p></div>
              <div className="normal-cell-3 first-row"><p>Soporte 12x5</p></div>
              <div className="normal-cell-3 first-row"><p>Soporte 24x7</p></div>
            </div>
          </div>
          <div className="soporte-table">
            <div className="header-cell"><p>Horario de soporte<br/><span>(Madrid time zone)</span></p></div>
            <div className="row">
              <div className="normal-cell-3"><p><b>Horario de oficina ordinario</b><br/>Lunes a Jueves<br/>09:00-14:00 / 15:00 - 18:00<br/>Viernes 09:00 - 15:00<br/><br/><b>Horario mes de agosto</b><br/>Lunes a Viernes 09:00 - 15:00</p></div>
              <div className="normal-cell-3"><p>Lunes a Viernes<br/>9.00 - 21.00</p></div>
              <div className="normal-cell-3"><p>Lunes a Domingo<br/>24 horas<br/>365 días al año</p></div>
            </div>
          </div>
          <div className="soporte-table">
            <div className="header-cell"><p>Dedicación máxima mensual para consultas o incidencias</p></div>
            <div className="row">
              <div className="normal-cell-3"><p>1,5 jornadas</p></div>
              <div className="normal-cell-3"><p>2 jornadas</p></div>
              <div className="normal-cell-3"><p>3 jornadas</p></div>
            </div>
          </div>
          <div className="soporte-table">
            <div className="header-cell"><p>SLA Tiempo de respuesta</p></div>
            <div className="row">
              <div className="normal-cell-3"><p>1 hora</p></div>
              <div className="normal-cell-3"><p>1 hora</p></div>
              <div className="normal-cell-3"><p>1 hora</p></div>
            </div>
          </div>
          <div className="soporte-table">
            <div className="header-cell"><p>Nº puestas en producción mensuales fuera de horario<br/><span>(después de las 22:00)</span></p></div>
            <div className="row">
              <div className="normal-cell-1"><p>1 puesta en producción / mes</p></div>
            </div>
          </div><br/>
          <h3>¿Por qué es necesario contar con una empresa de soporte técnico?</h3>
          <p>No todos sabemos de todo y no todos sabemos al nivel más adecuado. Por esta razón, y al ponerte en manos de expertos en tecnología con una contrastada experiencia en ecosistemas de alto rendimiento y servicios críticos, te brindarán tranquilidad a la hora de resolver problemas de rendimiento, del sistema o de funcionamiento.</p>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Dónde ofrecemos nuestro servicio de soporte técnico?</h2><hr></hr>
          <p>Todos nuestros servicios, así como los modelos de soporte se brindan de forma remota a través de la utilización de herramientas de comunicación y colaboración de primer nivel. <b>Herramientas como Microsoft Teams, Slack, Skype y otras, junto con el correo electrónico y teléfonos directos</b>, permitirán contar con nuestro equipo como si estuvieran a tu lado.</p><Location></Location>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default SoporteValencia